import React from 'react';
import classnames from 'classnames';
import getFormattedText from '../../utils/getFormattedText';

import FadeUpIntoView from '../FadeUpIntoView';
import LinkButton from '../LinkButton';

const TextGrid = ({
  title,
  subtitle,
  items,
  linkText,
  link,
  theme,
  reduced,
}) => {
  return (
    <section
      className={classnames('panel', 'panel--inset', 'text-grid', {
        [`text-grid--${theme}`]: theme,
        [`text-grid--has-button`]: linkText && link,
        [`text-grid--reduced`]: reduced,
      })}
    >
      {title && title.trim().length > 0 && (
        <FadeUpIntoView>
          <h2 className="text-grid__title">{getFormattedText(title)}</h2>
        </FadeUpIntoView>
      )}
      <ul className="text-grid__items">
        {items.map((item, i) => {
          return (
            <FadeUpIntoView
              as="li"
              key={`textGrid-${i}`}
              extraClass="text-grid__item"
            >
              <span className="text-grid__inner">{item}</span>
            </FadeUpIntoView>
          );
        })}
      </ul>
      {linkText && link && (
        <LinkButton
          text={linkText}
          link={link}
          extraClass="text-grid__button"
          theme={theme}
          hasArrow={true}
        />
      )}
    </section>
  );
};

export default TextGrid;
