import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { shuffle } from 'lodash';

import FadeUpIntoView from '../components/FadeUpIntoView';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SplitTextPanels from '../components/SplitTextPanels';
import TextGrid from '../components/TextGrid';
import IconGrid from '../components/IconGrid';
import DoubleImage from '../components/DoubleImage';
import ProjectGrid from '../components/ProjectGrid';
import DescriptorPanel from '../components/DescriptorPanel';
import Spacer from '../components/Spacer';
import HorizontalSlider from '../components/HorizontalSlider';
import ClientLogosGrid from '../components/ClientLogosGrid';

const Work = () => {
  const [gridItems, setGridItems] = useState({});

  const data = useStaticQuery(graphql`
    query ServicesPageQuery {
      content: contentfulServicesPage {
        metaTitle
        metaDescription
        heroTitle
        heroLeadParagraph {
          heroLeadParagraph
        }
        descriptorPanelTitle
        descriptorPanelLink
        descriptorPanelLinkLabel
        productFeatures {
          title
          text {
            text
          }
        }
        productFeaturesLink {
          label
          url
        }
        textGridTitle
        textGridSubtitle
        textGridIcons {
          title
          icon {
            file {
              src: url
            }
          }
        }
        testimonial {
          testimonial
        }
        testimonialCredit
        doubleImage1 {
          alt: description
          fluid(maxWidth: 1260, quality: 95) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        doubleImage2 {
          alt: description
          fluid(maxWidth: 685, quality: 95) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        splitTextPanelsTitle
        splitTextPanelsLeftTitle
        splitTextPanelsLeftSubtitle
        splitTextPanelsLeftParagraph {
          splitTextPanelsLeftParagraph
        }
        splitTextPanelsRightTitle
        splitTextPanelsRightSubtitle
        splitTextPanelsRightParagraph {
          splitTextPanelsRightParagraph
        }
        textGrid2Title
        textGrid2Subtitle
        textGrid2Items
        footerPromo
        footerPromoTheme
      }
      workGrid: contentfulWorkPage {
        projects {
          slug
          title
          formattedTitle
          textColour: gridTheme
          client
          category
          image: gridImage {
            fluid(maxWidth: 1440, quality: 95) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          imageHover: gridImageHover {
            fluid(maxWidth: 1440, quality: 95) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
        footerPromo
        footerPromoTheme
      }
    }
  `);

  const {
    content: {
      metaTitle,
      metaDescription,
      heroTitle,
      heroLeadParagraph: { heroLeadParagraph },
      descriptorPanelTitle,
      descriptorPanelLink,
      descriptorPanelLinkLabel,
      productFeatures,
      productFeaturesLink,
      textGridTitle,
      textGridSubtitle,
      textGridIcons,
      testimonial,
      testimonialCredit,
      doubleImage1,
      doubleImage2,
      splitTextPanelsTitle,
      splitTextPanelsLeftTitle,
      splitTextPanelsLeftSubtitle,
      splitTextPanelsLeftParagraph: { splitTextPanelsLeftParagraph },
      splitTextPanelsRightTitle,
      splitTextPanelsRightSubtitle,
      splitTextPanelsRightParagraph: { splitTextPanelsRightParagraph },
      textGrid2Title,
      textGrid2Subtitle,
      textGrid2Items,
      footerPromo,
      footerPromoTheme,
    },
    workGrid: { projects },
  } = data;

  useEffect(() => {
    setGridItems(shuffle(projects).slice(0, 4));
  }, [projects]);

  return (
    <Layout
      headerTheme="color"
      footerPromo={footerPromo}
      footerPromoTheme={footerPromoTheme}
    >
      <SEO title={metaTitle} description={metaDescription} />
      <section className="panel lead-panel lead-panel--services">
        <div className="lead-panel__inner">
          <FadeUpIntoView>
            <h1 className="lead-panel__title">{heroTitle.replace('/', '')}</h1>
          </FadeUpIntoView>
          <FadeUpIntoView delayBy={2}>
            <p className="lead-panel__subtitle">{heroLeadParagraph}</p>
          </FadeUpIntoView>
        </div>
      </section>

      <DescriptorPanel
        title={descriptorPanelTitle}
        link={descriptorPanelLink}
        linkLabel={descriptorPanelLinkLabel}
      />
      <Spacer variant="services1" />
      {productFeatures.length > 1 && (
        <HorizontalSlider panels={productFeatures} link={productFeaturesLink} />
      )}
      <Spacer variant="services1" />
      <section className="panel panel--red panel--lead panel--quarter-right">
        <FadeUpIntoView>
          <p className="testimonial">{testimonial.testimonial}</p>
        </FadeUpIntoView>
        <FadeUpIntoView as="span" extraClass="credit" delayBy={2}>
          {testimonialCredit}
        </FadeUpIntoView>
      </section>
      <Spacer variant="services2" />
      <IconGrid
        title={textGridTitle}
        subtitle={textGridSubtitle}
        items={textGridIcons}
        theme="light"
        noBottom={true}
      />
      <ClientLogosGrid page="services" />
      <DoubleImage image1={doubleImage1} image2={doubleImage2} />
      <SplitTextPanels
        title={splitTextPanelsTitle}
        leftPanel={{
          title: splitTextPanelsLeftTitle,
          subtitle: splitTextPanelsLeftSubtitle,
          text: splitTextPanelsLeftParagraph,
        }}
        rightPanel={{
          title: splitTextPanelsRightTitle,
          subtitle: splitTextPanelsRightSubtitle,
          text: splitTextPanelsRightParagraph,
        }}
      />
      <TextGrid
        title={textGrid2Title}
        subtitle={textGrid2Subtitle}
        items={textGrid2Items}
      />
      {gridItems.length > 0 && (
        <ProjectGrid projects={gridItems} inFlow={true} limited={true} />
      )}
    </Layout>
  );
};

export default Work;
