import React from 'react';

import AnimateBy from '../AnimateBy';

import classnames from 'classnames';

const MegaTitle = ({
  title,
  subtitle,
  text,
  titleArrow,
  leftMobile,
  alignLeft,
  underline,
  theme,
  reducedSpace,
  extraClass,
  skip,
}) => {
  return (
    <section
      className={classnames('panel', 'mega-title', {
        [`mega-title--left-always`]: alignLeft,
        [`mega-title--left-mobile`]: leftMobile,
        [`mega-title--underline`]: underline,
        [`mega-title--reduced-space`]: reducedSpace,
        [`mega-title--${theme}`]: theme,
        [extraClass]: extraClass,
      })}
    >
      <h1
        className={classnames('mega-title__title', {
          [`mega-title__title--arrow`]: titleArrow,
        })}
      >
        <AnimateBy by="line" on="view" skip={skip}>
          {title}
        </AnimateBy>
      </h1>
      {(subtitle || text) && <span className="mega-title__break" />}
      {subtitle && (
        <h2 className="mega-title__subtitle">
          <AnimateBy by="line" on="view">
            {subtitle}
          </AnimateBy>
        </h2>
      )}
      {text && (
        <p className="mega-title__text">
          <AnimateBy by="line" on="view">
            {text}
          </AnimateBy>
        </p>
      )}
    </section>
  );
};

export default MegaTitle;
