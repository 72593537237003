import React, { memo } from 'react';
import { SplitText } from '@cyriacbr/react-split-text';

import FadeUpOnLoad from '../FadeUpOnLoad';
import FadeUpIntoView from '../FadeUpIntoView';

const AnimateBy = ({ by, on, children, extra, skip }) => {
  const El = on === 'load' ? FadeUpOnLoad : FadeUpIntoView;

  if (typeof window == `undefined`) {
    return <span>{children}</span>;
  }

  return (
    <div className="animate-by">
      <SplitText
        LetterWrapper={({ children }) => children}
        LineWrapper={({ lineIndex, children }) =>
          by === 'line' ? (
            <El
              extraClass="animate-by__el animate-by__el--line"
              delayBy={skip && skip + lineIndex}
              as="div"
            >
              {children}{' '}
            </El>
          ) : (
            children
          )
        }
        WordWrapper={({ wordIndex, children }) =>
          by === 'word' ? (
            <El
              extraClass="animate-by__el animate-by__el--word"
              delayBy={skip && skip + wordIndex}
              as="span"
            >
              {children}{' '}
            </El>
          ) : (
            children
          )
        }
      >
        {children}
      </SplitText>
      {extra && (
        <El
          extraClass="animate-by__el animate-by__el--word"
          delayBy={skip && 10}
        >
          {extra}{' '}
        </El>
      )}
    </div>
  );
};

export default memo(AnimateBy);
