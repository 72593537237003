import React, { memo } from 'react';

import SwipeUpImage from '../SwipeUpImage';

const DoubleImage = ({ image1, image2 }) => {
  return (
    <section className="double-image">
      <SwipeUpImage
        image={image1}
        extraClass="double-image__image double-image__image--1"
      />
      <SwipeUpImage
        image={image2}
        extraClass="double-image__image double-image__image--2"
      />
    </section>
  );
};

export default memo(DoubleImage);
