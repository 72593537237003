import React from 'react';

import AnimateBy from '../AnimateBy';
import FadeUpIntoView from '../FadeUpIntoView';
import LinkButton from '../LinkButton';
import MegaTitle from '../MegaTitle';

import classnames from 'classnames';

const SplitTextPanels = ({ title, leftPanel, rightPanel }) => {
  return (
    <section
      className={classnames('panel', 'panel--inset', 'split-text-panels')}
    >
      <MegaTitle
        title={title}
        alignLeft={true}
        underline={true}
        theme="light"
        extraClass="split-text-panels__mega-title"
      />

      <div className="split-text-panels__panel">
        <FadeUpIntoView>
          <h2 className="split-text-panels__panel-title">{leftPanel.title}</h2>
        </FadeUpIntoView>
        <div className="split-text-panels__panel-content">
          <FadeUpIntoView delayBy={2}>
            <h3 className="split-text-panels__panel-subtitle">
              {leftPanel.subtitle}
            </h3>
          </FadeUpIntoView>
          <FadeUpIntoView delayBy={4}>
            <p className="split-text-panels__panel-text">{leftPanel.text}</p>
          </FadeUpIntoView>
          <LinkButton
            text="Get in touch"
            link="/contact/"
            extraClass="split-text-panels__button"
            hasArrow={true}
          />
        </div>
      </div>

      <div className="split-text-panels__panel">
        <h2 className="split-text-panels__panel-title">
          <AnimateBy by="line" on="view">
            {rightPanel.title}
          </AnimateBy>
        </h2>
        <div className="split-text-panels__panel-content">
          <FadeUpIntoView delayBy={2}>
            <h3 className="split-text-panels__panel-subtitle">
              {rightPanel.subtitle}
            </h3>
          </FadeUpIntoView>
          <FadeUpIntoView delayBy={4}>
            <p className="split-text-panels__panel-text">{rightPanel.text}</p>
          </FadeUpIntoView>
          <LinkButton
            text="Get in touch"
            link="/contact/"
            extraClass="split-text-panels__button"
            hasArrow={true}
          />
        </div>
      </div>
    </section>
  );
};

export default SplitTextPanels;
