import React from 'react';
import classnames from 'classnames';
import getFormattedText from '../../utils/getFormattedText';

import FadeUpIntoView from '../FadeUpIntoView';
import LinkButton from '../LinkButton';

const IconGrid = ({
  title,
  subtitle,
  items,
  linkText,
  link,
  theme,
  reduced,
  mobileOnly,
  noBottom,
}) => {
  return (
    <section
      className={classnames('panel', 'panel--inset', 'icon-grid', {
        [`icon-grid--${theme}`]: theme,
        [`icon-grid--has-button`]: linkText && link,
        [`icon-grid--reduced`]: reduced,
        [`icon-grid--mobile-only`]: mobileOnly,
        [`icon-grid--no-bottom`]: noBottom,
      })}
    >
      {title && title.trim().length > 0 && (
        <FadeUpIntoView>
          <h2 className="icon-grid__title">{getFormattedText(title)}</h2>
        </FadeUpIntoView>
      )}
      {subtitle && subtitle.trim().length > 0 && (
        <FadeUpIntoView>
          <h3 className="icon-grid__subtitle">{subtitle}</h3>
        </FadeUpIntoView>
      )}

      <ul className="icon-grid__items">
        {items.map(({ title, icon }, i) => {
          return (
            <FadeUpIntoView
              as="li"
              key={`iconGrid-${i}`}
              extraClass="icon-grid__item"
            >
              <div
                className="icon-grid__item-icon"
                style={{
                  backgroundImage: `url('${icon.file.src}')`,
                }}
              />
              <h4 className="icon-grid__item-title">{title}</h4>
            </FadeUpIntoView>
          );
        })}
      </ul>

      {linkText && link && (
        <LinkButton
          text={linkText}
          link={link}
          extraClass="icon-grid__link"
          theme={theme}
          hasArrow={true}
        />
      )}
    </section>
  );
};

export default IconGrid;
